<template lang="pug">
  footer
    div(class="footer-section")
      div(class="about-list") 关于 EMR Sim
      div EMR Sim 是专为教学设计的电子病历系统。
      div 它提供了一种现实、可控的方式让学生接触
      div 电子病历系统，同时结合情景病例，循序渐 
      div 进的提高学生的临床思维能力。
      div &nbsp;
    div(class="footer-section", v-if="showEdEhrOrg")
      ed-ehr-org-footer
    div(class="footer-section")
      div © 2024 - 西姆医教
    div(v-if="isAdminZone", class="error-testing")
      button(@click="throwError")  错误测试
    //ui-button(@buttonClicked="toggleAppLms")
      span {{ appLmsEnabled ? 'Disable App LMS ' : 'Enable App LMS' }}
</template>

<script>
import StoreHelper from '../../helpers/store-helper'
import EdEhrOrgFooter from '@/app/edehr-org/EdEhrOrgFooter'
import edherorg from '@/app/edehr-org/ed-ehr-org'
import UiLink from '@/app/ui/UiLink'
import UiButton from '@/app/ui/UiButton.vue'
export default {
  components: {
    UiButton,
    UiLink,
    EdEhrOrgFooter
  },
  computed: {
    appLmsEnabled () { return this.$store.getters['appLmsStore/appLmsEnabled']},
    apiData () { return StoreHelper.getApiData()   },
    authData () { return StoreHelper.getAuthData() },
    appVersion () {
      return StoreHelper.getAppVersion()
    },
    classMain () {
      return this.showEdEhrOrg ? 'is-6' : 'is-12'
    },
    isAdminZone () { return StoreHelper.inZoneAdmin() },
    showEdEhrOrg () {
      return edherorg.isEdEhrOrg()
    },
  },
  methods: {
    throwError () {
      throw new Error('Sentry Error')
    },
    toggleAppLms () {
      console.log('toggle app lms', this.appLmsEnabled)
      this.$store.dispatch('appLmsStore/setAppLmsEnabled', !this.appLmsEnabled)
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';
footer {
  display: flex;
  flex-direction: column;

  .about-list > a{
    display: inline-block;

  }
  a {
    color: white;
  }
  .footer-section {
    margin-bottom: 1rem;
  }
}
.error-testing button {
  background-color: transparent;
  color: black
}
</style>
