<template lang='pug'>
div
  div 时间:  &nbsp;
    span(class='bold') {{ visitDay }},{{ visitTime }} (入院第 {{visitDayNum}} 天)
</template>

<script>
import UiInfo from '@/app/ui/UiInfo'
import { t18EhrFunctions } from '@/helpers/ehr-t18'
import { currentSimDayNumber, currentSimTime, currentSimDayDate } from '@/helpers/date-helper'
export default {
  components: { UiInfo },
  data () {
    return {
    }
  },
  computed: {
    ehrText () { return t18EhrFunctions()},
    visitDay () { return currentSimDayDate() },
    visitDayNum () { return currentSimDayNumber() },
    visitTime () { return currentSimTime() }
  }
}
</script>

<style scoped>

</style>
