import { formatTimeStr } from '@/helpers/ehr-utils'

export const Text = {
  // STUDENT_INTRO: 'Click the edit icon to fill in the form.'
  EHRDATA_CAN_NOT_BE_EMPTY: '病例不能为空',
  EHRDATA_HAS_INVALID_PAGES: (extras) => `数据包含无效值: ${extras}`,
  LICENSE_FULL_TEXT: 'This work is licensed under a Creative Commons Attribution 4.0 International License. See http://creativecommons.org/licenses/by/4.0/',
  LICENSE_TEXT: 'Creative Commons Attribution',
  LICENSE_MUST_BE: 'Licence must be Creative Commons Attribution',
  FUNCTION_OUT_OF_CONTEXT: (f) => `Coding error using ${f} out of context`,
  // API load errors
  IS_INVALID_CHECKSET: (key) => `The checkset ${key} definition is missing the options property`,
  LOBJ_MUST_HAVE_LICENSE: 'Learning object data must have a valid licence',
  LOBJ_FILE_MISSING_SEED: 'Learning object file did not contain a case study',
  MISSING_VISIT_ID: 'No visit id available',
  MISSING_API_URL: 'This system requires the URL to the API',
  MISSING_AUTH_TOKEN: 'You need to be authenticated to view this',
  PARAMETERS_ERROR: 'Parameters Error',
  SEED_MUST_HAVE_LICENSE: 'Case study must have a valid licence',
  SEED_MUST_HAVE_EHRDATA: '病例必须包含 EMR 数据',
  SEND_FOR_EVAL: '发送以供评估',
  SEND_FOR_EVAL_TITLE: '发送作业给导师，以供评估',
  SEND_FOR_EVAL_BODY: '请确认是否要将作业发送给导师评估？一旦提交，您将不能继续修改。',
  IS_SUBMITTED: '状态: 无法编辑',

  // Authentication
  EXPIRED_REFRESH_TOKEN: '刷新令牌已过期',
  EXPIRED_TOKEN: '会话已过期。请返回 LMS 以重试。',
  INVALID_TOKEN: (err) => `无效令牌 \n ${err}`,
  REQUIRE_AUTHENTICATION: '您需要经过身份验证才能继续此操作',
  TOKEN_FETCHING_ERROR: '获取令牌数据时出错',


  // Assignments constants
  MUST_HAVE_ASSIGNMENTS: 'ERROR the system should have assignments',
  UPDATE_ASSIGNMENT_ERROR: (err) => `error in update assignment ${err}`,
  GET_ASSIGNMENT_ERROR: (name, id) => `ERROR the could not get ${name} ${id}`,

  // Consumer constants
  UPDATE_CONSUMER_ERROR: (err) => `error in update consumer ${err}`,
  NO_CONSUMERS_ERROR: 'ERROR the system should have consumers',
  CANNOT_GET_CONSUMER_STORE: (name, id) => `ERROR the could not get ${name} ${id}`,

  // activityData
  GET_ACTIVITY_DATA_ERROR: (name, id) => `ERROR could not get ${name} ${id}`,
  CHANGE_ACTIVITY_ASSIGNMENT: 'Changing learning object for this activity.',
  GET_ACTIVITY_STORE_ERROR: (id) => `ERROR Api failed to get activity ${id}`,
  // userStore
  GET_USER_STORE_ERROR: (name, id) => `ERROR the could not get ${name} ${id}`,


  // ClassList constants
  EVAL_DONE: '评估已完成。让学生查看评估记录。',
  NOT_SUBMITTED: '未提交',
  SUBMITTED: '已提交',

  // class list per student actions
  EVALUATE_TIP: '在数据模式下评估',
  EVALUATE_BL: '评估',
  EVAL_IN_EHR_TIP: '在 EMR 模式下评估',
  EVAL_IN_EHR_BL: '在 EMR 模式下评估',
  EDIT_BLOCK_TIP: '禁止学生继续编辑作业。',
  EDIT_BLOCK_BL: '禁止学生继续编辑',
  EDIT_OPEN_TIP: '允许学生继续编辑作业。',
  EDIT_OPEN_BL: '允许学生继续编辑',

  ACTIVITY_PAGE: {
    ACTIVITY_LABEL: 'LMS 活动',
    ACTIVITY_MISSING: '无活动链接',
    COURSE_LABEL: '课程',
    DESCRIPTION: '说明',
    DATES: '时间',
    DATES_VAL: (c,l) => `创建于 ${formatTimeStr(c)}. 更新于 ${formatTimeStr(l)} `,
    CASE_STUDY: '病例',
    CLASS_LIST: '班级',
    CLASS_LIST_BTN: '进入班级列表',
    EMPTY_CLASSLIST: '尚未有学生参与此活动',
    EVALUATION: '评估',
    SCRATCH: '笔记',
    LOBJ: '活动',
    GOTOLOBJ: '查看活动',
    STATUS: '状态',
    STUDENTS_VAL: (total, submitted) => `已有 ${total} 名学生参与， ${submitted} 名学生提交作业`,
    WARNING_RESET: '点击重置按钮可以退回到默认模式，该模式下学生可以看到病例的完整信息，而不是分阶段信息，并且可以自主控制病例中的时间',
    WARNING_RESET_TITLE: '重置模拟控制',
    RESET_BUTTON_LABEL: '重置',
    WARNING_SET_SIM_TIME:  '设定时间功能定义了学生看到的病例开始的日期和具体时间' +
      '',
    WARNING_SET_SIM_TIME_TITLE: '设定模拟时间',
    SET_SIM_TIME_BUTTON:   '设定时间',
    SET_SIMULATION_TIMER: '计时器功能可以呈现病例中的时间流逝。输入分钟数，点击开始/暂停按钮, 学生界面的病例时间会随之流逝/暂停',
    SET_SIMULATION_TIMER_TITLE: '设定模拟计时器',
    SIM_TIME_INITIALIZE_TITLE: '默认模式',
    SIM_TIME_INITIALIZE: '在默认模式下学生可以看到病例的完整信息，而不是分阶段信息，并且可以自主控制病例中的时间'
  },
  CLASS_LIST_PAGE: {
    EVALUATION: '评估记录',
    INDEX: 'Index',
    SUBMITTED: '已提交',
    STATUS: '状态',
    LOBJ: '活动'
  },
  CONSUMER_PAGE: {
    NAME: '名称',
    NAME_TIP: '学习管理系统的名称',
    DESCRIPTION: '描述',
    KEY: '密钥',
    SECRET: '密令',
    TYPE: '类型',
    FAMILY: '母版',
    VERSION_PRODUCT: '版本',
    VERSION_LTI: 'LTI',
    GUID: 'GUID',
    DATES: '时间',
    USERS: '用户',
    USER_COUNT: '用户数量',
    VISITS_TOTAL: '最近访问次数',
    VISITS_STUDENTS: '学生访问次数',
    VISITS_INSTRUCTORS: '导师访问次数',
    ACTIVITIES: 'LMS 活动',
    LOBJS: '活动数量',
    SEEDS: '病例数量',

  },

  COURSE_PAGE: {
    COURSE_PAGE_INTRO: '此页面列出了该课程中包含的所有活动，点击活动名称以查看其详细信息',
    COURSES_PAGE_INTRO: '此页面列出了系统中的所有课程，点击课程名称以查看其包含的活动。',
    DESCRIPTION: '描述',
    ACTIVITY_STUDENT_SELECT_NAV: '点击活动名称以查看该活动的详细信息，或点击 \'进入 EMR\' 按钮进入编辑电子病历页面。',
    ACTIVITY_INSTRUCTOR_SELECT_NAV: '点击活动名称以查看其详细信息。'
  },
  UPDATE_COURSE_ERROR: (err) => `更新课程时出错 ${err}`,
  COURSE_DIALOG: {
    TITLES: {
      edit: '编辑课程概要',
      view: '查看课程概要'
    },
    BUTTON_TEXT: {
      EDIT: '编辑课程',
      VIEW: '查看概要'
    },
    ERRORS: {
      NAME_REQUIRED: '课程名称为必填项',
    },
    LABELS: {
      NAME: '课程名称',
      ID: 'LMS 课程 ID',
      TITLE: 'LMS 课程名称',
      DESCRIPTION: '课程描述',
      LMS_DESCRIPTION: 'LMS 课程描述',
      TYPE: 'LMS 类型',
      CANCEL_BUTTON_VIEW_MODE: '关闭',
      CANCEL_BUTTON_EDIT_MODE: '取消'
    },
    DESCRIPTIONS: {
      ID: '学习管理系统中课程的内部 ID',
      TITLE: '由学习管理系统提供的课程名称。',
      DESCRIPTION: '课程描述',
      LMS_DESCRIPTION: '由学习管理系统提供的课程描述。',
      TYPE: '由学习管理系统提供的课程类型。',
    }

  },

  FILES_PAGE: {
    P1: '下面列出了服务器上保存的所有图片和文件资料。',
    P2: '导师可以通过点击右上角的按钮上传图片和文件。这些图片和文件可添加在 EMR 页面中使用。',
    ADD_LABEL: '上传资料',
    ADD_TITLE: '上传资料',
    DIALOG_TEXT: (max) => `上传你想要在病例中使用的文件资料，如X光片、实验室报告和其他医学图像等。上传后，所有导师均可使用。 支持格式包括图片和 pdf，最大文件大小为 1 MB 。
`
  },
  INSTRUCTOR_TOOLS: {
    ACTIVITIES: '当前活动',
    GOTO_ACTIVITY: (name) => `查看活动 ${name}`,
    GOTO_COURSE: (name) => `查看课程 ${name}`,
    ACTIVITIES_TOOLTIP: '查看当前活动和班级列表',
    COURSES_LABEL: '所有课程', // match route names
    COURSES_NAV_LABEL: '所有课程', // shown on sidebar
    COURSES_TOOLTIP: '查看所有课程',
    EXIT_LABEL: '退回到 LMS',
    EXIT_TOOLTIP: '退回到学习管理系统',
    LMS_LABEL: '统计',
    LMS_TOOLTIP: '查看统计资料',
    LOBJ_LABEL: '活动',
    LOBJ_TOOLTIP: '查看所有活动',
    FILE_LABEL: '资料',
    FILE_TOOLTIP: '查看图片和文件资料',
    SEED_LIST_LABEL: '病例',
    SEED_LIST_TOOLTIP: '查看所有病例',
    // CONFIGURATION SETTINGS
    DESIGNER_MODE_LABEL: '编辑模式',
    DESIGNER_MODE_TURN_OFF_TOOLTIP: '退回到常规导师角色',
    DESIGNER_MODE_TURN_ON_TOOLTIP: '进行内容编辑',
    // SHOW_BUTTON_LABELS: (state) => state ? 'Hide button labels' : 'Show button labels',
    // SHOW_BUTTON_LABELS_TOOLTIP: (state) => state ? 'Buttons show icons only' : 'Buttons show icons and text labels',
    USER_SETTINGS: '设置',
    USER_SETTING_TIP: '调整个人设置',
  },
  LOBJ_LIST_LINK_TEXT: '活动列表',
  LOBJ_ACTIONS: {
    COPY: '复制',
    COPY_TP: (name) => `复制`,
    DETAILS: '详情',
    DETAILS_TP: '查看此活动的详情',
    PROPERTIES: '概要',
    PROPERTIES_TP: '编辑概要',
    DOWNLOAD: '下载',
    DOWNLOAD_TP: '下载',
    DELETE: '删除',
    DELETE_DISABLED: '不能删除正在使用的活动',
    DELETE_CONFIRM: {
      TITLE: (name) => `删除`,
      DESCRIPTION: (name) => `确定要删除该活动吗?`,
    },
    DUPLICATE_CONFIRM: {
      TITLE: (name) => `确认复制`,
      DESCRIPTION: (name) => `确定要复制该活动吗?`,
    }
  },
  LOBJ_DIALOG: {
    TITLES: {
      edit: '编辑活动概要',
      create: '创建新活动',
      import: '导入新活动',
      import_sub: '导入本地文件，可以是活动文件，也可以是病例文件。'
    },
    ERRORS: {
      NAME_REQUIRED: '活动名称为必填项',
      APP_TYPE_REQUIRED: '需要选定病例'
    },
    LABELS: {
      FILE_INPUT: '选择 json 格式的活动文件或病例文件',
      LOBJ: '活动',
      LOBJ_NAME: '名称 (必填项)',
      DESCRIPTION: '说明',
      SEED_OBJ: '病例',
      SEED_NAME: '名称  (必填项)',
      SEED_DESCRIPTION: '描述',
      APP_TYPE: '类型',
      SEED_TAGS: '标签',
      SEED_CONTRIB: '作者 (选填)',
      SEED_VERSION: '版本 (选填)',
    }
  },
  LOBJ_SHARED: {
    APP_TYPE: {
      EHR: 'This case study is for the electronic health record (EHR) application.',
      LIS: 'This case study is for the lab information system (LIS) application.',
      NONE: 'No application type is active'
    },
    APP_TYPE_TP: 'The application type sets which system is used. Current choices are EHR (typically for health care professions in a hospital setting), LIS (lab information system of medical laboratories), and someday soon EMR (typically for health care professions in the community setting). ',
    LOBJ_EXPLAINED: '活动需绑定到课程中使用，且可被不同课程重复使用。活动中可提前绑定想要给学生展示的病例，也可以不绑定，让学生自行搜索病例。',
    DESCRIPTION_EXPLAINED: '在此编写向学生展示的说明。学生打开患者电子病历页面时，会在界面上方显示此说明。',
    SEED_DESCRIPTION_TP: 'The case study description is important for people like you so you can know what it contains and what it can be good for.',
    SEED_TAGS_TP: 'Tags let you categorize case studies.',
    SEED_CONTRIB_TP: 'A list of people who contributed to the case study.',
    SEED_VERSION_TP: 'You can indicate a versions of content.',
  },
  LOBJ_IMPORT_DIALOG: {

  },
  LOBJ_PAGE: {
    ACTIVITIES: '活动',
    LOBJ: '活动',
    DESCRIPTION: '说明',
    DATES: '时间',
    SEED: '病例',
    USED: '用于',
    USED_VAL: (cnt) => `${cnt} 门课程`,
    UPLOAD: '上传',
    UPLOAD_TP: '上传',
  },
  ROUTE_NAMES: {
    ADMIN: '管理员登录',
    ADMIN_DASH: '管理员面板',
    ADMIN_ACTIVITIES: 'Admin activities',
    ADMIN_CONSUMER: 'Admin consumer',
    ADMIN_USERS: 'Admin users',
    ABOUT: '关于',
    ABOUT_EDEHR: '关于 EMR Sim',
    ABOUT_SASS: '关于 EMR Sim',
    ACTIVITY: '活动',
    CLASS_LIST: '班级列表',
    CONSUMER: '统计',
    CONSUMERS: '使用方',
    COURSES: '我的活动',
    FILES: '资料',
    LOBJS: '活动',
    LOBJ: '活动',
    SEEDS: '病例',
    SEED_VIEW: '病例',
    SEED_CONDENSED: '病例',
    ACTIVITY_SIM_CONTROLLER: '模拟控制',
    LOBJ_SIM_CONTROLLER: '模拟阶段',
    USER_SETTINGS: '设置'
  },
  SEED_ACTIONS: {
    SEED_DEVELOPERS_MENU: '内容创建工具',
    MENU_DETAILS: '病例详情',
    MENU_DETAILS_TP: '返回病例详情页面',
    MENU_RETURN: '返回列表',
    MENU_RETURN_TP: '返回病例列表',
    DETAILS:'详情',
    DETAILS_TP: '查看病例详情和修改信息',
    DOWNLOAD: '下载',
    DOWNLOAD_TP: '下载',
    EDIT: '在 EMR 中编辑',
    EDIT_TP: '在 EMR 中查看或编辑',
    CREATE_LOBJ: '创建活动',
    CREATE_LOBJ_TP: '用此病例创建活动',
    PROPERTIES: '概要',
    PROPERTIES_TP: '编辑概要',
    createLearningObjectTip:'创建新活动',
    VIEW: '查看数据',
    VIEW_TP: '查看',
  },
  SEEDS_PAGE: {
    FILTER_TP: '由 EMR 页面筛选',
    CREATE: '创建新的',
    CREATE_TP: '创建新病例',
    DOWNLOAD: '全部下载',
    DOWNLOAD_TP: '全部下载'
  },
  SEED_VIEW_PAGE: {
    SEED_LABEL: '病例',
    DATES: '时间',
    DESCRIPTION: '描述',
    CONTRIBUTORS: '作者',
    LOBJ_LABEL: '活动',
    LOBJ_VALUE: (cnt) => `用于 ${cnt} 个活动${ cnt === 1 ? cnt : 's' }`,
    STATS: '页面',
    STATS_VALUE: (cnt) => `包含 ${cnt} 个 EMR 页面`,
    VERSION: '版本',
  },
  USER_SETTINGS_PAGE: {
    PAGE_TITLE: '设置',
    COMPACT_BUTTON_LABEL: '紧凑',
    OPEN_BUTTON_LABEL: '完整',
    EHR_LAYOUT_LABEL: '布局',
    EHR_LAYOUT_TITLE: '布局',
    EHR_LAYOUT_TIP: ''
  }
}

export const TextLearningObjects = {
  nameLabel: '活动名称',
  intro: '活动决定了学生点击课程链接后查看到的内容 ',
  explain: 'The Learning Object defines the content that will be used by a number of activities. ' +
    'Each academic year instructors clone existing courses with their activities. ' +
    'These activities are linked to Learning Objects which, in turn, are linked to a case study ' +
    'and provides instructions about how to use the case study to achieve a set of learning objectives. ' +
    '<br/>' +
    'Note how one case study can be reused by any number of learning objects and ' +
    'how one Learing Object is reused by any number of activities',
  step2: 'Describe what the student needs to do with the content and what learning objectives they ought to accomplish. ' +
    '<br/>' +
    'After someone creates an activity in your learning management system they need to connect it to EdEHR content. ' +
    'The first time they click on the link they will see a list of Learning Objects to select from.  ' +
    'The description you enter here will help that user decide which content to use. ' +
    '<br/>' +
    'But also instructors may use this description in the activity\'s description property and ' +
    'that is the text the students will see when they come to do the assignment. '
}
