export function inside () {

  return [
    {
      path: '/ehr/patient',
      name: 'patient',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Patient.vue'),
      meta: { layout: 'inside', label: '患者资料', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/demographics',
      name: 'demographics',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Demographics.vue'),
      meta: { layout: 'inside', label: '个人信息', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/allergies',
      name: 'allergies',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Allergies.vue'),
      meta: { layout: 'inside', label: '过敏', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history',
      name: 'history',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/History.vue'),
      meta: { layout: 'inside', label: '病史', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/medical',
      name: 'medical',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Medical.vue'),
      meta: { layout: 'inside', label: '病史', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/psychosocial',
      name: 'psychosocial',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Psychosocial.vue'),
      meta: { layout: 'inside', label: '日常生活状况', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/surgical',
      name: 'surgical',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Surgical.vue'),
      meta: { layout: 'inside', label: '心理社会状况', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/immunization',
      name: 'immunization',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Immunization.vue'),
      meta: { layout: 'inside', label: '疫苗接种史', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/family-history',
      name: 'family-history',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/FamilyHistory.vue'),
      meta: { layout: 'inside', label: '家族史', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/history/care-team',
      name: 'care-team',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CareTeam.vue'),
      meta: { layout: 'inside', label: '个人史', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/past-appointments',
      name: 'past-appointments',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/PastAppointments.vue'),
      meta: { layout: 'inside', label: '既往入院记录', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/current',
      name: 'current',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Current.vue'),
      meta: { layout: 'inside', label: '本次入院', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/visit-details',
      name: 'visit-details',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/VisitDetails.vue'),
      meta: { layout: 'inside', label: '入院', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/vital-signs',
      name: 'vital-signs',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/VitalSigns.vue'),
      meta: { layout: 'inside', label: '生命体征', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/fluid-balance',
      name: 'fluid-balance',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/FluidBalance.vue'),
      meta: { layout: 'inside', label: '出入量', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments',
      name: 'assessments',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Assessments.vue'),
      meta: { layout: 'inside', label: '评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/neurological',
      name: 'neurological',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Neurological.vue'),
      meta: { layout: 'inside', label: '一般状况评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/respiratory',
      name: 'respiratory',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Respiratory.vue'),
      meta: { layout: 'inside', label: '头颈部评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/cardiovascular',
      name: 'cardiovascular',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Cardiovascular.vue'),
      meta: { layout: 'inside', label: '胸部及乳房评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/gastrointestinal',
      name: 'gastrointestinal',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Gastrointestinal.vue'),
      meta: { layout: 'inside', label: '心脏及血管评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/genitourinary',
      name: 'genitourinary',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Genitourinary.vue'),
      meta: { layout: 'inside', label: '腹部评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/musculoskeletal',
      name: 'musculoskeletal',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Musculoskeletal.vue'),
      meta: { layout: 'inside', label: '脊柱四肢关节评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/pain',
      name: 'pain',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Pain.vue'),
      meta: { layout: 'inside', label: '疼痛评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/biopsychosocial',
      name: 'biopsychosocial',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Biopsychosocial.vue'),
      meta: { layout: 'inside', label: '神经系统评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders',
      name: 'orders',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Orders.vue'),
      meta: { layout: 'inside', label: '非药物医嘱', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/no-med-orders',
      name: 'no-med-orders',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/NoMedOrders.vue'),
      meta: { layout: 'inside', label: '非药物医嘱', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/referrals',
      name: 'referrals',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Referrals.vue'),
      meta: { layout: 'inside', label: '转科医嘱', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/lab-requisitions',
      name: 'lab-requisitions',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/LabRequisitions.vue'),
      meta: { layout: 'inside', label: '检查医嘱', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/meds',
      name: 'meds',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Meds.vue'),
      meta: { layout: 'inside', label: '药物医嘱', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/mar',
      name: 'mar',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Mar.vue'),
      meta: { layout: 'inside', label: '药物管理记录', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/discharge',
      name: 'discharge',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Discharge.vue'),
      meta: { layout: 'inside', label: '出院小结', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/billing',
      name: 'billing',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Billing.vue'),
      meta: { layout: 'inside', label: '计费', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/chart',
      name: 'chart',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Chart.vue'),
      meta: { layout: 'inside', label: '报告和文书', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/progress-notes',
      name: 'progress-notes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/ProgressNotes.vue'),
      meta: { layout: 'inside', label: '病程记录', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/care-plan',
      name: 'care-plan',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CarePlan.vue'),
      meta: { layout: 'inside', label: '护理计划', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports',
      name: 'reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Reports.vue'),
      meta: { layout: 'inside', label: '报告和文书', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/consults',
      name: 'consults',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/Consults.vue'),
      meta: { layout: 'inside', label: '会诊报告', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/lab-reports',
      name: 'lab-reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/LabReports.vue'),
      meta: { layout: 'inside', label: '实验室检查报告', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/diagnostic-reports',
      name: 'diagnostic-reports',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DiagnosticReports.vue'),
      meta: { layout: 'inside', label: '影像检查报告', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/chart/reports/operative-anaesthesia',
      name: 'operative-anaesthesia',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/OperativeAnaesthesia.vue'),
      meta: { layout: 'inside', label: '手术和麻醉记录', topLevel: 'chart', zone: 'ehr' }
    },
    {
      path: '/ehr/external',
      name: 'external',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/External.vue'),
      meta: { layout: 'inside', label: '外部资源', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/assessment-tools',
      name: 'assessment-tools',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/AssessmentTools.vue'),
      meta: { layout: 'inside', label: '标准评估工具', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/code-lookup',
      name: 'code-lookup',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CodeLookup.vue'),
      meta: { layout: 'inside', label: 'Code lookup', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/diagnostic-codes',
      name: 'diagnostic-codes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DiagnosticCodes.vue'),
      meta: { layout: 'inside', label: 'Diagnostic codes (ICD-10)', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/intervention-codes',
      name: 'intervention-codes',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/InterventionCodes.vue'),
      meta: { layout: 'inside', label: 'Intervention codes', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/external/case-mix-group',
      name: 'case-mix-group',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/CaseMixGroup.vue'),
      meta: { layout: 'inside', label: 'Case mix group', topLevel: 'external', zone: 'ehr' }
    },
    {
      path: '/ehr/current/assessments/integumentary-assessment',
      name: 'integumentary-assessment',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/IntegumentaryAssessment.vue'),
      meta: { layout: 'inside', label: '伤口评估', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/current/discharge-plan',
      name: 'discharge-plan',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/DischargePlan.vue'),
      meta: { layout: 'inside', label: '出院计划', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab',
      name: 'med-lab',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLab.vue'),
      meta: { layout: 'inside', label: 'Med Lab', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/hematology',
      name: 'hematology',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/Hematology.vue'),
      meta: { layout: 'inside', label: 'Med Lab Hematology', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-chemistry',
      name: 'med-lab-chemistry',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabChemistry.vue'),
      meta: { layout: 'inside', label: 'Med Lab Chemistry', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-urinalysis',
      name: 'med-lab-urinalysis',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabUrinalysis.vue'),
      meta: { layout: 'inside', label: 'Med Lab Urinalysis', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/current/orders/order-sets',
      name: 'order-sets',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/OrderSets.vue'),
      meta: { layout: 'inside', label: 'Clinical Order Sets', topLevel: 'current', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-accession',
      name: 'med-lab-accession',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabAccession.vue'),
      meta: { layout: 'inside', label: 'Med Lab Accessioning', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/patient/special-indicators',
      name: 'special-indicators',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/SpecialIndicators.vue'),
      meta: { layout: 'inside', label: 'Special Indicators (Risk)', topLevel: 'patient', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-phone-log',
      name: 'med-lab-phone-log',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabPhoneLog.vue'),
      meta: { layout: 'inside', label: 'Phone Log', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/levey-jennings',
      name: 'levey-jennings',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/custom/LeveyJennings.vue'),
      meta: { layout: 'inside', label: 'Levey-Jennings', topLevel: 'med-lab', zone: 'ehr' }
    },
    {
      path: '/ehr/med-lab/med-lab-body',
      name: 'med-lab-body',
      component: () =>
        import(/* webpackChunkName: "chunk-[request][index]" */ './inside/views/MedLabBody.vue'),
      meta: { layout: 'inside', label: 'Body Fluids', topLevel: 'med-lab', zone: 'ehr' }
    }
  ]
}
